import React from 'react'

import Layout from '../components/layout'
import './contact.css'
import { StyledIconTagLink } from '../components/tag'
import { graphql } from 'gatsby'

const ContactPage = ({ data }) => {
  let socialAccounts = data.allContactsJson.edges.map(details => details.node)

  return (
    <Layout>
      <div className="contact-details">
        <h4 className="contact-msg">
          Follow me on my social media accounts to get latest updates. If you
          want to talk about tech drop a hi!
        </h4>
        <ul className="social-media">
          {socialAccounts.map((account, index) => {
            return (
              <StyledIconTagLink
                tag={account.icon}
                key={index}
                br="25%"
                href={account.link}
              >
                {account.serviceName}
              </StyledIconTagLink>
            )
          })}
        </ul>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  {
    allContactsJson {
      edges {
        node {
          serviceName
          link
          icon
          color
        }
      }
    }
  }
`
